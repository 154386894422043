var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.rubricCriterias.filter((x) => x.evaluation_criteria_micro != null)
      .length > 0 || _vm.allows_crud
  )?_c('div',{staticClass:"page-break mb-4"},[_vm._m(0),_c('div',{staticClass:"duoc-specification-table-container"},[_c('b-table-simple',{attrs:{"border":""}},[_c('b-thead',[_c('b-th',{staticClass:"primary-cell text-center",staticStyle:{"width":"18%"}},[_vm._v("RESULTADO DE APRENDIZAJE")]),_c('b-th',{staticClass:"primary-cell",staticStyle:{"width":"25%"}},[_vm._v("INDICADOR DE LOGRO (IL)")]),_c('b-th',{staticClass:"primary-cell",staticStyle:{"width":"7%"}},[_vm._v("PONDERACIÓN INDICADOR DE LOGRO")]),_c('b-th',{staticClass:"primary-cell",staticStyle:{"width":"25%"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.dimension", false, "Dimensión" ).toUpperCase())+" (IE) "),(_vm.allows_crud && !_vm.rubric)?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                `Crear ${_vm.$getVisibleNames(
                  'evaluations2.rubric',
                  false,
                  'Pauta'
                )}`
              ),expression:"\n                `Crear ${$getVisibleNames(\n                  'evaluations2.rubric',\n                  false,\n                  'Pauta'\n                )}`\n              ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticStyle:{"color":"white"},attrs:{"title":`Crear ${_vm.$getVisibleNames(
                'evaluations2.rubric',
                false,
                'Pauta'
              ).toUpperCase()}`,"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                  `modal-create-rubric-st-${_vm.instrument_id}-${_vm.container_instrument_id}-${_vm.container_instrument_id}`
                )}}}):_vm._e()],1)]),_c('b-th',{staticClass:"primary-cell",staticStyle:{"width":"7%"}},[_vm._v("PONDERACIÓN INDICADOR DE EVALUACIÓN")]),(_vm.showLinkedQuestion)?_c('b-th',{staticClass:"primary-cell",staticStyle:{"width":"7%"}},[_vm._v("N° DE PREGUNTA O ÍTEMS")]):_vm._e()],1),_c('b-tbody',[_vm._l((_vm.studyUnits),function(study_unit,index){return [_vm._l((_vm.evaluationCriteriaMicro.filter(
              (x) => x.study_unit == study_unit.id
            )),function(evaluation_criteria_micro,index2){return [(
                _vm.rubricCriterias.filter(
                  (x) =>
                    x.evaluation_criteria_micro ==
                    evaluation_criteria_micro.id
                ).length > 0
              )?_vm._l((_vm.rubricCriterias.filter(
                  (x) =>
                    x.evaluation_criteria_micro ==
                    evaluation_criteria_micro.id
                )),function(rubric_criteria,index3){return _c('b-tr',{key:'study_unit' +
                  index +
                  'evaluation_criteria_micro' +
                  index2 +
                  'rubric_criteria' +
                  index3},[(index2 == 0 && index3 == 0)?_c('b-td',{staticClass:"secundary-cell text-ra",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  },attrs:{"rowspan":_vm.rubricCriterias.filter((x) =>
                      _vm.evaluationCriteriaMicro
                        .filter((a) => a.study_unit == study_unit.id)
                        .map((b) => b.id)
                        .includes(x.evaluation_criteria_micro)
                    ).length +
                    _vm.evaluationCriteriaMicro.filter(
                      (a) =>
                        a.study_unit == study_unit.id &&
                        _vm.rubricCriterias.filter(
                          (x) => x.evaluation_criteria_micro == a.id
                        ).length == 0
                    ).length}},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" N°"+_vm._s(study_unit.order)+" ")]),_c('SentenceContainer',{class:{
                        'unselectable-text':
                          (_vm.user && _vm.user.groups.includes(7)) ||
                          _vm.preview_evaluation,
                      },attrs:{"unique_id":'sentence-study_unit' +
                        index +
                        'evaluation_criteria_micro' +
                        index2 +
                        'rubric_criteria' +
                        index3,"Sentence":study_unit,"showColorCognitiveLevel":_vm.allows_crud,"showCognitiveLevel":!_vm.preview_evaluation,"boundedTextContainer":false}})],1)]):_vm._e(),(index3 == 0)?_c('b-td',{staticClass:"secundary-cell text-il",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  },attrs:{"rowspan":_vm.rubricCriterias.filter(
                      (x) =>
                        x.evaluation_criteria_micro ==
                        evaluation_criteria_micro.id
                    ).length}},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" "+_vm._s(study_unit.order)+"."+_vm._s(evaluation_criteria_micro.order)+" ")]),_c('SentenceContainer',{class:{
                        'unselectable-text':
                          (_vm.user && _vm.user.groups.includes(7)) ||
                          _vm.preview_evaluation,
                      },attrs:{"unique_id":'sentence-2-study_unit' +
                        index +
                        'evaluation_criteria_micro' +
                        index2 +
                        'rubric_criteria' +
                        index3,"Sentence":evaluation_criteria_micro,"showColorCognitiveLevel":_vm.allows_crud,"showCognitiveLevel":!_vm.preview_evaluation,"boundedTextContainer":false}})],1)]):_vm._e(),(index3 == 0)?_c('b-td',{staticClass:"secundary-cell ponderation-cell-indicator-evaluation",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  },attrs:{"rowspan":_vm.rubricCriterias.filter(
                      (x) =>
                        x.evaluation_criteria_micro ==
                        evaluation_criteria_micro.id
                    ).length}},[_c('DuocSpecificationTableInput',{attrs:{"test_id":_vm.instrument_id,"evaluation_criteria_micro_id":evaluation_criteria_micro.id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"allows_crud":false}})],1):_vm._e(),_c('b-td',{staticClass:"secundary-cell"},[_c('div',{staticClass:"d-flex"},[_c('SentenceContainer',{staticClass:"w-100",class:{
                        'unselectable-text':
                          (_vm.user && _vm.user.groups.includes(7)) ||
                          _vm.preview_evaluation,
                      },attrs:{"unique_id":'sentence-3-study_unit' +
                        index +
                        'evaluation_criteria_micro' +
                        index2 +
                        'rubric_criteria' +
                        index3,"Sentence":rubric_criteria,"showColorCognitiveLevel":_vm.allows_crud,"showCognitiveLevel":!_vm.preview_evaluation,"boundedTextContainer":false}}),(_vm.allows_crud && _vm.rubric && !_vm.rubric.is_locked)?_c('div',[_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                          `Editar ${_vm.$getVisibleNames(
                            'manual.dimension',
                            false,
                            'Dimensión'
                          )}`
                        ),expression:"\n                          `Editar ${$getVisibleNames(\n                            'manual.dimension',\n                            false,\n                            'Dimensión'\n                          )}`\n                        ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],attrs:{"title":`Editar ${_vm.$getVisibleNames(
                          'manual.dimension',
                          false,
                          'Dimensión'
                        ).toUpperCase()}`,"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                            `modal-edit-rubric-criteria-st-${index}-${index2}-${index3}-${evaluation_criteria_micro.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`
                          )}}}),_c('b-modal',{attrs:{"id":`modal-edit-rubric-criteria-st-${index}-${index2}-${index3}-${evaluation_criteria_micro.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`,"title":'Modificar ' +
                          _vm.$getVisibleNames(
                            'manual.dimension',
                            false,
                            'Dimensión'
                          ),"size":"lg","hide-footer":"","no-enforce-focus":""}},[_c('RubricCriteriaForm',{attrs:{"rubric_id":_vm.rubric.id,"selected_evaluation_criteria_micro":evaluation_criteria_micro,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"RubricCriteria":rubric_criteria,"show_delete_button":true,"order":rubric_criteria.order},on:{"updated":function($event){return _vm.$bvModal.hide(
                              `modal-edit-rubric-criteria-st-${index}-${index2}-${index3}-${evaluation_criteria_micro.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`
                            )},"deleted":function($event){return _vm.$bvModal.hide(
                              `modal-edit-rubric-criteria-st-${index}-${index2}-${index3}-${evaluation_criteria_micro.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`
                            )}}})],1)],1):_vm._e()],1),(
                      _vm.rubricCriterias.filter(
                        (x) =>
                          x.evaluation_criteria_micro ==
                          evaluation_criteria_micro.id
                      ).length ==
                      index3 + 1
                    )?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-auto ml-auto"},[(_vm.allows_crud && _vm.rubric && !_vm.rubric.is_locked)?[_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                            `Crear ${_vm.$getVisibleNames(
                              'manual.dimension',
                              false,
                              'Dimensión'
                            )}`
                          ),expression:"\n                            `Crear ${$getVisibleNames(\n                              'manual.dimension',\n                              false,\n                              'Dimensión'\n                            )}`\n                          ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],attrs:{"title":`Crear ${_vm.$getVisibleNames(
                            'manual.dimension',
                            false,
                            'Dimensión'
                          ).toUpperCase()}`,"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                              `modal-created-rubric-criteria-st-${evaluation_criteria_micro.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`
                            )}}}),_c('b-modal',{attrs:{"id":`modal-created-rubric-criteria-st-${evaluation_criteria_micro.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`,"title":`Crear ${_vm.$getVisibleNames(
                            'manual.dimension',
                            false,
                            'Dimensión'
                          )}`,"size":"lg","hide-footer":"","no-enforce-focus":""}},[_c('RubricCriteriaForm',{attrs:{"rubric_id":_vm.rubric.id,"selected_evaluation_criteria_micro":evaluation_criteria_micro,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"order":_vm.rubricCriterias.length > 0
                                ? _vm.rubricCriterias[_vm.rubricCriterias.length - 1]
                                    .order + 1
                                : 1},on:{"created":function($event){return _vm.$bvModal.hide(
                                `modal-created-rubric-criteria-st-${evaluation_criteria_micro.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`
                              )}}})],1)]:_vm._e(),(_vm.allows_crud && !_vm.rubric)?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-danger.top.noninteractive",value:(
                            `Para agregar una ${_vm.$getVisibleNames(
                              'manual.dimension',
                              false,
                              'Dimensión'
                            )} debe crear una ${_vm.$getVisibleNames(
                              'evaluations2.rubric',
                              false,
                              'Pauta'
                            )} `
                          ),expression:"\n                            `Para agregar una ${$getVisibleNames(\n                              'manual.dimension',\n                              false,\n                              'Dimensión'\n                            )} debe crear una ${$getVisibleNames(\n                              'evaluations2.rubric',\n                              false,\n                              'Pauta'\n                            )} `\n                          ",modifiers:{"v-danger":true,"top":true,"noninteractive":true}}]},[_c('button-add',{attrs:{"disable":"true","size":"sm"}})],1)]:_vm._e()],2)]):_vm._e()]),_c('b-td',{staticClass:"secundary-cell ponderation-cell",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                    'text-danger': _vm.showWarningRubricCriteria(
                      evaluation_criteria_micro
                    ),
                  }},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-danger.top.noninteractive",value:(
                      `${
                        _vm.showWarningRubricCriteria(evaluation_criteria_micro)
                          ? 'La suma de las ponderaciones de los (IE) es mayor que la ponderación del (IL)'
                          : ''
                      }`
                    ),expression:"\n                      `${\n                        showWarningRubricCriteria(evaluation_criteria_micro)\n                          ? 'La suma de las ponderaciones de los (IE) es mayor que la ponderación del (IL)'\n                          : ''\n                      }`\n                    ",modifiers:{"v-danger":true,"top":true,"noninteractive":true}}]},[_vm._v(_vm._s(rubric_criteria.weighing)+"% ")])]),(_vm.showLinkedQuestion)?_c('b-td',{staticClass:"secundary-cell ponderation-cell",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  }},[_vm._l((_vm.filterQuestionsByRubricCriteria(
                      rubric_criteria.id
                    )),function(question){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                      `${_vm.$htmlToPlainText(question.title)}`
                    ),expression:"\n                      `${$htmlToPlainText(question.title)}`\n                    ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],key:question.id + '-' + rubric_criteria.id},[(question.segment_order != null)?_c('span',[_vm._v(_vm._s(question.segment_order)+".")]):_vm._e(),_vm._v(" "+_vm._s(question.order)+" ")])}),(
                      _vm.filterQuestionsByRubricCriteria(rubric_criteria.id)
                        .length == 0
                    )?_c('div',[_vm._v(" N/A ")]):_vm._e()],2):_vm._e()],1)}):[_c('b-tr',{key:'study_unit' + index + 'evaluation_criteria_micro' + index2},[(index2 == 0)?_c('b-td',{staticClass:"secundary-cell text-ra",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  },attrs:{"rowspan":_vm.rubricCriterias.filter((x) =>
                      _vm.evaluationCriteriaMicro
                        .filter((a) => a.study_unit == study_unit.id)
                        .map((b) => b.id)
                        .includes(x.evaluation_criteria_micro)
                    ).length +
                    _vm.evaluationCriteriaMicro.filter(
                      (a) =>
                        a.study_unit == study_unit.id &&
                        _vm.rubricCriterias.filter(
                          (x) => x.evaluation_criteria_micro == a.id
                        ).length == 0
                    ).length}},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" N°"+_vm._s(study_unit.order)+" ")]),_c('SentenceContainer',{class:{
                        'unselectable-text':
                          (_vm.user && _vm.user.groups.includes(7)) ||
                          _vm.preview_evaluation,
                      },attrs:{"unique_id":'sentence-study_unit' +
                        index +
                        'evaluation_criteria_micro' +
                        index2,"Sentence":study_unit,"showColorCognitiveLevel":_vm.allows_crud,"showCognitiveLevel":!_vm.preview_evaluation,"boundedTextContainer":false}})],1)]):_vm._e(),_c('b-td',{staticClass:"secundary-cell text-il",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  }},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" "+_vm._s(study_unit.order)+"."+_vm._s(evaluation_criteria_micro.order)+" ")]),_c('SentenceContainer',{class:{
                        'unselectable-text':
                          (_vm.user && _vm.user.groups.includes(7)) ||
                          _vm.preview_evaluation,
                      },attrs:{"unique_id":'sentence-study_unit' +
                        index +
                        'evaluation_criteria_micro' +
                        index2,"Sentence":evaluation_criteria_micro,"showColorCognitiveLevel":_vm.allows_crud,"showCognitiveLevel":!_vm.preview_evaluation,"boundedTextContainer":false}})],1)]),_c('b-td',{staticClass:"secundary-cell ponderation-cell-indicator-evaluation",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  }},[_c('DuocSpecificationTableInput',{attrs:{"test_id":_vm.instrument_id,"evaluation_criteria_micro_id":evaluation_criteria_micro.id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"allows_crud":false}})],1),_c('b-td',{staticClass:"secundary-cell",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  }},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-auto mr-auto"},[_c('p',[_c('b',[_vm._v("N/A")])])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-auto ml-auto"},[(_vm.allows_crud && _vm.rubric)?[_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                              `Crear ${_vm.$getVisibleNames(
                                'manual.dimension',
                                false,
                                'Dimensión'
                              )}`
                            ),expression:"\n                              `Crear ${$getVisibleNames(\n                                'manual.dimension',\n                                false,\n                                'Dimensión'\n                              )}`\n                            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],attrs:{"title":`Crear ${_vm.$getVisibleNames(
                              'manual.dimension',
                              false,
                              'Dimensión'
                            ).toUpperCase()}`,"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                                `modal-created-rubric-criteria-st-${evaluation_criteria_micro.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`
                              )}}}),_c('b-modal',{attrs:{"id":`modal-created-rubric-criteria-st-${evaluation_criteria_micro.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`,"title":`Crear ${_vm.$getVisibleNames(
                              'manual.dimension',
                              false,
                              'Dimensión'
                            )}`,"size":"lg","hide-footer":"","no-enforce-focus":""}},[_c('RubricCriteriaForm',{attrs:{"rubric_id":_vm.rubric.id,"selected_evaluation_criteria_micro":evaluation_criteria_micro,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"order":_vm.rubricCriterias.length > 0
                                  ? _vm.rubricCriterias[
                                      _vm.rubricCriterias.length - 1
                                    ].order + 1
                                  : 1},on:{"created":function($event){return _vm.$bvModal.hide(
                                  `modal-created-rubric-criteria-st-${evaluation_criteria_micro.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`
                                )}}})],1)]:_vm._e(),(_vm.allows_crud && !_vm.rubric)?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-danger.top.noninteractive",value:(
                              `Para agregar una ${_vm.$getVisibleNames(
                                'manual.dimension',
                                false,
                                'Dimensión'
                              )} debe crear una ${_vm.$getVisibleNames(
                                'evaluations2.rubric',
                                false,
                                'Pauta'
                              )} `
                            ),expression:"\n                              `Para agregar una ${$getVisibleNames(\n                                'manual.dimension',\n                                false,\n                                'Dimensión'\n                              )} debe crear una ${$getVisibleNames(\n                                'evaluations2.rubric',\n                                false,\n                                'Pauta'\n                              )} `\n                            ",modifiers:{"v-danger":true,"top":true,"noninteractive":true}}]},[_c('button-add',{attrs:{"disable":"true","size":"sm"}})],1)]:_vm._e()],2)])])]),_c('b-td',{staticClass:"secundary-cell ponderation-cell",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  }},[_c('b',[_vm._v("N/A")])]),(_vm.showLinkedQuestion)?_c('b-td',{staticClass:"secundary-cell ponderation-cell",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  }},[_vm._v(" N/A ")]):_vm._e()],1)]]})]})],2),_c('b-tfoot',[_c('b-tr',[_c('b-td',{staticClass:"primary-cell",attrs:{"colspan":"2"}}),_c('b-td',{staticClass:"primary-cell",class:{
              'unselectable-text':
                (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
            }},[_c('strong',[_vm._v("TOTAL")])]),_c('b-td',{staticClass:"primary-cell",class:{
              'unselectable-text':
                (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
            },style:({
              color:
                _vm.totalWeighingTestEvaluationCriteriaMicros != 100 ? 'red' : '',
            }),attrs:{"title":_vm.totalWeighingTestEvaluationCriteriaMicros != 100
                ? 'La ponderación total debe ser igual a 100.'
                : ''}},[_c('strong',[_vm._v(_vm._s(_vm.totalWeighingTestEvaluationCriteriaMicros)+"%")])]),_c('b-td',{staticClass:"primary-cell",class:{
              'unselectable-text':
                (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
            },style:({ color: _vm.totalWeighing != 100 ? 'red' : '' }),attrs:{"title":_vm.totalWeighing != 100
                ? 'La ponderación total debe ser igual a 100.'
                : ''}},[_c('strong',[_vm._v(_vm._s(_vm.totalWeighing)+"%")])]),(_vm.showLinkedQuestion)?_c('b-td',{staticClass:"primary-cell"}):_vm._e()],1)],1)],1)],1),_c('b-modal',{attrs:{"id":`modal-create-rubric-st-${_vm.instrument_id}-${_vm.container_instrument_id}-${_vm.container_instrument_id}`,"title":`Crear ${_vm.$getVisibleNames(
      'evaluations2.rubric',
      false,
      'Pauta'
    )}`,"size":"lg","hide-footer":""}},[_c('NewRubricForm',{attrs:{"test_id":_vm.instrument_id},on:{"closeModal":function($event){return _vm.$bvModal.hide(
          `modal-create-rubric-st-${_vm.instrument_id}-${_vm.container_instrument_id}-${_vm.container_instrument_id}`
        )}}})],1)],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left mb-3"},[_c('span',{staticClass:"title-evaluative-agent"},[_vm._v("Tabla de Especificaciones")])])
}]

export { render, staticRenderFns }